import React, { useContext } from "react";
import { Context } from "redux/Store";
// import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
// import { TypeA, TypeC } from "common/callouts";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/dry-skin/eucerin-advanced-repair-cream-front.webp";
import backImage from "images/body/dry-skin/eucerin-advanced-repair-cream-back.webp";
import frontZoomImage from "images/body/dry-skin/eucerin-advanced-repair-cream-front-zoom.webp";
import backZoomImage from "images/body/dry-skin/eucerin-advanced-repair-cream-back-zoom.webp";

// import img2 from "images/body/dry-skin/chart-eucerin-ar-cream-vs-cerave-data.webp";
import img3 from "images/body/dry-skin/chart-eucerin-ar-cream-patient-survey.webp";
// import img4 from "images/callouts/section-cout-advanced-repair-resource.webp";
import img5 from "images/callouts/cout-footer-aquaphor.webp";
import img6 from "images/callouts/sunproducts2x.webp";
import img7 from "images/body/dry-skin/chart-eucerin-cream-pediatric-tolerability.webp";

import "./eucerinadvancedrepaircream.scss";

function DrySkinEucerinAdvancedRepairCreamPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-dryskin page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Advanced Repair Cream"
        pageDescription="Learn about Eucerin Advanced Repair Cream"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#6ACAD5"
              categoryName="DRY SKIN"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Advanced Repair Cream"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold teal-text sub-title">
                  For daily dry skin care, it outperforms in hydration
                  <sup>1</sup>
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Advanced Repair Cream
                  </span>{" "}
                  is for patients with very dry skin who need intense,
                  long-lasting hydration. The unique formulation includes
                  purposeful ingredients that go beyond ceramides.
                </p>
                <ul className="teal-bullets">
                  <li>
                    <span className="font-semi-bold">
                      Superior hydration vs CeraVe<sup>&reg;</sup> Moisturizing
                      Cream<sup>1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated with natural moisturizing factors (NMFs) plus
                      ceramide NP
                    </span>
                  </li>
                  <li>
                    <span>
                      Daily hydration that replenishes what skin needs
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold teal-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb">
                  <span className="font-bold">
                    Natural moisturizing factors:
                  </span>{" "}
                  Urea, sodium lactate, and others
                </p>
                <ul className="teal-bullets">
                  <li>
                    <span>Improves water binding in stratum corneum</span>
                  </li>
                </ul>
                <p className="font-semi-bold">Ceramide NP</p>
                <ul className="teal-bullets">
                  <li>
                    <span>
                      Strengthens and sustains moisture barrier; reduces water
                      loss
                    </span>
                  </li>
                </ul>
                <p className="font-semi-bold">Shea butter</p>
                <ul className="teal-bullets">
                  <li>
                    <span>Conditions the skin</span>
                  </li>
                </ul>
                <p className="font-semi-bold">Sunflower seed oil</p>
                <ul className="teal-bullets">
                  <li>
                    <span>
                      Improves the moisture barrier and decreases transepidermal
                      water loss
                    </span>
                  </li>
                </ul>
                <p className="font-semi-bold">Cholesterol</p>
                <ul className="teal-bullets">
                  <li>
                    <span>
                      Supports skin’s moisture barrier to reduce dehydration
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold teal-text sub-title">
                    Formulated for daily use to hydrate sensitive skin
                  </p>
                  <ul className="checkmarks teal">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>dye-free</span>
                    </li>
                    <li>
                      <span>paraben-free</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                  </ul>
                </div>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Superior
                  hydration vs CeraVe Moisturizing Cream, measured by
                  Corneometer<sup>&reg;</sup> after 10 days of use (n=35).
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            {/* <AccordionContent
							accordionHeading='RESULTS VS CERAVE'
							productThemeColor='eucerin-teal'
							id='resultsvscerave'
						>
							<Row align='center' justify='center' direction='row'>
								<Col sm={12} md={8}>
									<img
										src={img2}
										className='accordion-chart'
										alt='Eucerin Advanced Repair Cream Vs CeraVe Mositurizing Cream Chart'
									/>
									<p className='footnotes'>
										<span className='font-bold'>Study design:</span>{' '}
										Double-blinded, bilateral comparison of the moisturizing
										efficacy of Eucerin Advanced Repair Cream vs CeraVe
										Moisturizing Cream (N=33). Products were randomly assigned
										and applied once daily for 10 days followed by a 5-day
										regression period.
									</p>
									<p className='references-symbols double'>
										**Statistically significant improvement compared to baseline
										(<span className='font-italic'>p&lt;</span>0.001).
									</p>
									<p className='references-symbols'>
										*Statistically significant improvement compared to baseline
										(<span className='font-italic'>p&lt;</span>0.05).
									</p>
									<p className='references-symbols last'>
										<sup>&Dagger;</sup>Statistically significant difference
										between treatments in favor of Eucerin (
										<span className='font-italic'>p&lt;</span>0.001).
									</p>
									<p className='references'>
										<span className='font-bold'>Reference: 1.</span> Data on
										file. Beiersdorf Inc.
									</p>
								</Col>
								<Col sm={12} md={4}>
									<div className='bordered-content'>
										Eucerin provided
										<br className='show-desktop' /> significantly more
										<br className='show-desktop' /> moisture than CeraVe
										<br className='show-desktop' /> at Day 10<sup>1</sup>
									</div>
								</Col>
							</Row>
						</AccordionContent> */}
            <AccordionContent
              accordionHeading="TOLERABILITY"
              productThemeColor="eucerin-teal"
              id="tolerability"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img7}
                    className="accordion-chart"
                    alt="Tolerability study of pediatrics response from using Eucerin® Advanced Repair Cream"
                  />
                  <p className="references">
                    <span className="font-bold">Study design:</span> Children
                    ages 3 to 12 were randomized to Eucerin Advanced Repair
                    Lotion (n=33) or Cream (n=32) for once-daily, at-home use
                    for <nobr>14 days.</nobr> Clinical assessment at baseline
                    and Day 14, subjective assessment by parental questionnaire.
                  </p>
                  <p className="footnotes">
                    Statistically significant agreement for all statements (
                    <span className="font-italic">p</span>&lt;0.0001).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Clinically
                    <br className="show-desktop" /> assessed as “very
                    <br className="show-desktop" /> good
                    <br className="show-desktop" /> tolerability” for
                    <br className="show-desktop" /> all patients
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="SURVEY RESULTS"
              productThemeColor="eucerin-teal"
              id="surveyresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="HCP survey stats after using Advanced Repair Cream"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Over 9 out of 10
                    <br className="show-desktop" /> healthcare
                    <br className="show-desktop" /> professionals
                    <br className="show-desktop" /> (HCPs) would
                    <br className="show-desktop" /> recommend
                    <br className="show-desktop" /> Advanced
                    <br className="show-desktop" /> Repair Cream<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        {/* <section className='callouts-container gradient-bg section-support-resource light-blue'>
					<div className='inner-centered-container'>
						<Row>
							<Col sm={12} md={8} order={{ xs: 2, md: 1 }} className=''>
								<TypeC
									buttonText='DOWNLOAD HERE'
									buttonUrl={getUrlFromSlug(
										'eucerin-advanced-repair-formulation',
										state?.sanityData?.allResources
									)}
									className='center-v'
									isExternalLink={true}
									targetOpen='_blank'
								>
									<p className='reg-text-big'>
										Support your recommendation <br className='show-desktop' />{' '}
										with this resource
									</p>
									<p className='reg-text'>
										See how Eucerin Advanced Repair Cream significantly
										outperformed <br className='show-desktop' /> CeraVe
										Moisturizing Cream in hydrating dry skin.
									</p>
								</TypeC>
							</Col>
							<Col sm={12} md={4} order={{ xs: 1, md: 2 }} className=''>
								<img
									src={img4}
									alt='Eucerin Advanced Reapir Cream Recommendation'
									className='cout-product-img'
								/>
							</Col>
						</Row>
					</div>
				</section> */}
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="DRY, DAMAGED SKIN"
                  caption="Recommended to help heal dry,<br class='show-desktop' /> cracked skin"
                  buttonText="DRY, DAMAGED SKIN"
                  buttonUrl="/body/damagedskin/aquaphordrycrackedskin"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img6}
                  imgAlt="DISCOVER EUCERIN® SUN"
                  caption="Sun protection for all skin types<br class='show-desktop' /> and tones"
                  buttonText="DISCOVER EUCERIN<sup>®</sup> SUN"
                  buttonUrl="/sun"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DrySkinEucerinAdvancedRepairCreamPage;
